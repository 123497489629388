import * as React from 'react';
import {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {PageTitles} from '../../features/pageTitles';
import {CustomizedState} from '../../utils/customizedState';
import {ClientRoutes} from '../../features/clientRoutes';

export default function UserPasswordChangeStatus() {

    const location = useLocation();
    const state = location.state as CustomizedState;

    useEffect(() => {
        document.title = PageTitles.userForgotPassword;
    }, []);

    return (
        <div className="container account mt-4 mt-md-5">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <h1 className="card-title">Check your email.</h1>
                    <p>
                        An email has been sent to <strong>{state.emailAddress || "reset your password"}</strong>. Follow
                        the directions in the email to change your password.
                    </p>
                    <p>
                        <Link to={ClientRoutes.userChangePasswordRequest} className="float-start">Didn&apos;t receive
                            it?</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}