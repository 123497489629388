import axios from 'axios';
import {FetchStatus} from "../features/fetchStatus";

export async function fetchData(
    url: string,
    data: any,
    headers: any,
    signal: any = undefined,
    isFormData: boolean = false) {

    let isCanceled: boolean = false;
    let requiresAuth: boolean = false;
    let message: string = '';
    let results: any[] = [];
    let hasError: boolean = false;
    const errors: any[] = [];

    // TODO: Change fetchData to use fetchStatus.
    let fetchStatus: FetchStatus = FetchStatus.Ok;

    axios.defaults.timeout = 60000;

    // These are expected responses. This is the only way to get the error response from the server.
    const instance = axios.create({
        validateStatus: function (status) {
            return status >= 200;
        }
    });

    if (isFormData) {
        headers['Accept'] = '*/*';
        headers['Content-Type'] = 'multipart/form-data';
    }

    const config = {
        'headers': headers,
        'signal': signal
    };

    await instance.post(url, data, config)
        .then((response) => {
            // console.log('API SUCCESS: ' + JSON.stringify(response, undefined, 4));

            const responseStatus = response.status;
            const responseCode = response.data.code;
            const errorList = response.data.errors;

            message = response.data.message;

            // 401 response. Error is in message.
            if (responseCode === 'user_password_invalid') {
                hasError = true;
                return;
            }

            if (responseCode === 'user_email_unverified') {
                fetchStatus = FetchStatus.UserEmailUnverified;
                hasError = true;
                return;
            }

            // All other 401 responses need a new, valid token.
            if (responseStatus === 401) {
                requiresAuth = true;
                return;
            }

            if (response.data.access_token) {
                results = response.data;
                return;
            }

            if (responseCode === 'ok') {
                results = response.data.results;
                return;
            }

            // If not a validation error, there will be no error list. The message with have the error message.
            hasError = true;

            if (errorList?.length) {
                errorList.forEach(function (item: any) {
                    errors.push(
                        {'field': item.field, 'errorMessage': item.error_message}
                    );
                });
            }
        })
        .catch(error => {
            // console.log('API ERROR: ' + JSON.stringify(error, undefined, 4));

            if (error.message === 'canceled') {
                isCanceled = true;
                return;
            }

            hasError = true;
            message = error.message;
            console.log('UNHANDLED API ERROR: ' + JSON.stringify(error, undefined, 4));

            if (message === 'Network Error') {
                message = 'No internet connection.';
            }
        });

    return {fetchStatus, isCanceled, requiresAuth, message, results, hasError, errors};
}