import axios, {AxiosRequestConfig} from "axios";

export async function fetchFile(
    url: string,
    data: any,
    headers: any,
    signal: any = undefined,
    fileName: string) {

    let isCanceled: boolean = false;
    let requiresAuth: boolean = false;
    let message: string = '';
    let hasError: boolean = false;
    const errors: any[] = [];

    axios.defaults.timeout = 60000;

    // These are expected responses. This is the only way to get the error response from the server.
    const instance = axios.create({
        validateStatus: function (status) {
            return status >= 200;
        }
    });

    const config: AxiosRequestConfig = {
        headers: headers,
        signal: signal,
        responseType: 'blob'
    };

    await instance.post(url, data, config)
        .then((response) => {
            // console.log('API SUCCESS: ' + JSON.stringify(response, undefined, 4));

            const responseStatus = response.status;

            // All other 401 responses need a new, valid token.
            if (responseStatus === 401) {
                requiresAuth = true;
                return;
            }

            if (responseStatus !== 200) {
                const errorList = response.data.errors;
                message = response.data.message;

                // TODO: Bug. Axios returns empty data even though my API is returning a body.
                if (!message) {
                    message = 'An unexpected error occurred.';
                }

                // If not a validation error, there will be no error list. The message with have the error message.
                hasError = true;

                if (errorList?.length) {
                    errorList.forEach(function (item: any) {
                        errors.push(
                            {'field': item.field, 'errorMessage': item.error_message}
                        );
                    });
                }

                return;
            }

            // Create blob link to download.
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);

            document.body.appendChild(link);

            // Start download.
            link.click();
        })
        .catch(error => {
            if (error.message === 'canceled') {
                isCanceled = true;
                return;
            }

            hasError = true;
            message = error.message;
            console.log('UNHANDLED API ERROR: ' + JSON.stringify(error, undefined, 4));
        });

    return {isCanceled, requiresAuth, message, hasError, errors};
}