import {useEffect, useState} from "react";
import {ApiRoutes} from "../../apiRoutes";
import {useAuth} from "../../AuthProvider";
import {fetchData} from "../../utils/fetchData";
import {getHeaders} from "../../utils/requestHeaders";
import {FeatureStatus} from "../featureStatus";
import {
    getBalanceSheetInfo,
    getCcpInfo,
    getKpisVsIndustryInfo,
    getPrognosisVsIndustryInfo,
    getSeddaInfo
} from "./analysisUtils";

export default function useAnalysis(companyId: string, sectorKey: string, reportDate: string) {
    const {getToken} = useAuth();

    const [status, setStatus] = useState(FeatureStatus.IsLoading);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    // Fetched data in correct shape.
    const [companyCcp, setCompanyCcp] = useState<any>({});
    const [companyAssets, setCompanyAssets] = useState<any[]>([]);
    const [companyLiabilities, setCompanyLiabilities] = useState<any[]>([]);
    const [industryAssets, setIndustryAssets] = useState<any[]>([]);
    const [industryLiabilities, setIndustryLiabilities] = useState<any[]>([]);
    const [prognosesVsIndustry, setPrognosesVsIndustry] = useState<any>({});
    const [kpisVsIndustry, setKpisVsIndustry] = useState<any>({});
    const [seddaKpis, setSeddaKpis] = useState<any>({});

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async () => {
            if (status !== FeatureStatus.IsLoading) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(FeatureStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(FeatureStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    // Reset all results. Don't display partial results.
                    setCompanyCcp({});
                    setCompanyAssets([]);
                    setCompanyLiabilities([]);
                    setIndustryAssets([]);
                    setIndustryLiabilities([]);
                    setPrognosesVsIndustry({});
                    setKpisVsIndustry({});
                    setSeddaKpis({});

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            //
            // Company CPP.
            //

            const companyArgs = {
                'company_id': companyId,
                'period_end_date': reportDate
            };

            const coPrognosisCpp = await fetchData(ApiRoutes.companyMetricsGetPrognosesCcp, companyArgs, headers, signal);

            if (doExitEarly(coPrognosisCpp)) {
                return;
            }

            const coPrognosisCppResults = coPrognosisCpp.results;

            //
            // Company benchmarks.
            //

            const coBenchmarks = await fetchData(ApiRoutes.companyMetricsGetBenchmarks, companyArgs, headers, signal);

            if (doExitEarly(coBenchmarks)) {
                return;
            }

            const coBenchmarksResults = coBenchmarks.results;

            //
            // Company KPIs.
            //

            const companyKpiArgs = {
                'company_id': companyId,
                'period_end_date': reportDate,
                'kpi_transformations': ['none']
            };

            const coKpis = await fetchData(ApiRoutes.companyMetricsGetKpis, companyKpiArgs, headers, signal);

            if (doExitEarly(coKpis)) {
                return;
            }

            const coKpisResults = coKpis.results;

            const companyPrognosisArgs = {
                'company_id': companyId,
                'period_end_date': reportDate
            };

            const coPrognoses = await fetchData(ApiRoutes.companyMetricsGetPrognoses, companyPrognosisArgs, headers, signal);

            if (doExitEarly(coPrognoses)) {
                return;
            }

            const coPrognosesResults = coPrognoses.results;

            //
            // Industry benchmarks.
            //

            const indBenchmarkParams = {
                'sector_key': sectorKey,
                'period_end_date': reportDate
            };

            const indBenchmarks = await fetchData(ApiRoutes.industriesMetricsGetBenchmarks, indBenchmarkParams, headers, signal);

            if (doExitEarly(indBenchmarks)) {
                return;
            }

            const indBenchmarksResults = indBenchmarks.results;

            //
            // Industry KPIs.
            //

            const indKpiArgs = {
                'sector_key': sectorKey,
                'period_end_date': reportDate,
                'kpi_transformations': ['none'],
                'agg_keys': ['25%', '50%', '75%']
            };

            const indKpiAggs = await fetchData(ApiRoutes.industriesMetricsGetKpiAggs, indKpiArgs, headers, signal);

            if (doExitEarly(indKpiAggs)) {
                return;
            }

            const indKpiAggsResults = indKpiAggs.results;

            //
            // Industry prognoses.
            //

            const indPrognosisArgs = {
                'sector_key': sectorKey,
                'period_end_date': reportDate
            };

            const indPrognoses = await fetchData(ApiRoutes.industriesMetricsGetPrognoses, indPrognosisArgs, headers, signal);

            if (doExitEarly(indPrognoses)) {
                return;
            }

            const indPrognosesResults = indPrognoses.results;

            // Set state for all
            const coCpp = getCcpInfo(coPrognosisCppResults);
            setCompanyCcp(coCpp);

            const [companyAssets, companyLiabilities, indAssets, indLiabilities] = getBalanceSheetInfo(
                coBenchmarksResults,
                indBenchmarksResults);

            setCompanyAssets(companyAssets);
            setCompanyLiabilities(companyLiabilities);
            setIndustryAssets(indAssets);
            setIndustryLiabilities(indLiabilities);

            const prognosis = getPrognosisVsIndustryInfo(
                coKpisResults,
                coPrognosesResults,
                indKpiAggsResults,
                indPrognosesResults);

            setPrognosesVsIndustry(prognosis);

            const companyKpisByCategory = getKpisVsIndustryInfo(coKpisResults, indKpiAggsResults);
            setKpisVsIndustry(companyKpisByCategory);

            const seddaResult = getSeddaInfo(
                coKpisResults,
                coPrognosesResults,
                indKpiAggsResults,
                indPrognosesResults);
            setSeddaKpis(seddaResult);

            setStatus(FeatureStatus.IsLoaded);
        };

        postForm().then();

        return () => {
            controller.abort();
        };

    }, [status, companyId, sectorKey, reportDate, getToken]);

    const resetAnalysis = () => {
        setStatus(FeatureStatus.IsLoading);
        setMessage('');
        setValidationErrors([]);
    };

    const chartData = {
        companyCcp: companyCcp,
        companyAssets: companyAssets,
        companyLiabilities: companyLiabilities,
        industryAssets: industryAssets,
        industryLiabilities: industryLiabilities,
        prognosesVsIndustry: prognosesVsIndustry,
        kpisVsIndustry: kpisVsIndustry,
        seddaKpis: seddaKpis
    };

    return {
        status, message, validationErrors, chartData, resetAnalysis
    };
}