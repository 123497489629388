import {yupResolver} from '@hookform/resolvers/yup';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

import {ApiRoutes} from '../../apiRoutes';
import {PageTitles} from '../../features/pageTitles';
import {postData} from '../../utils/postData';
import {ClientRoutes} from '../../features/clientRoutes';

export default function UserPasswordChangeConfirmation() {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = PageTitles.userResetPassword;
    }, []);

    const validationSchema = Yup.object().shape({
        email_address: Yup.string()
            .required('Email is required.')
            .max(255, 'Email must not exceed 255 characters.')
            .email('Email is not valid.'),
        verification_code: Yup.string()
            .required('Verification code is required.')
            .min(6, 'Verification code must be 6 characters.')
            .max(6, 'Verification code must be 6 characters.'),
        password: Yup.string()
            .required('Password is required.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
                'Password must be at least 8 characters, including one number, one uppercase and lowercase letter, and one special character.'
            )
            .max(50, 'Password must not exceed 50 characters.'),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords do not match.')
    });

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setFocus,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        shouldFocusError: true
    });

    /* Get email and code from link. */
    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');
    const code = new URLSearchParams(search).get('code');

    /* Set defaults, if they exist. */
    useEffect(() => {
        reset({
            email_address: email,
            verification_code: code
        }, {keepDefaultValues: true});
    }, [reset, email, code]);

    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [firstErrorField, setFirstErrorField] = useState('');

    useEffect(() => {
        if (firstErrorField) {
            setFocus(firstErrorField);
        }
    }, [setFocus, firstErrorField]);

    const onSubmitHandler = async (data: any) => {
        setIsSaving(true);
        setFirstErrorField('');

        postData(ApiRoutes.userChangePassword, data)
            .then((response) => {
                setIsSaving(false);

                if (response.errorSummary) {
                    setErrorMessage(response.errorSummary);
                    setFirstErrorField(response.firstErrorField);

                    response.errorList.forEach(function (value: any) {
                        setError(value.field, {type: 'custom', message: value.error_message});
                    });
                } else {
                    setErrorMessage('');
                    navigate(ClientRoutes.userSignIn, {
                        state: {
                            'emailAddress': data.email_address,
                            'message': 'Your password has been changed.'
                        }
                    });
                }
            });
    };

    return (
        <div className="container account mt-4 mt-md-5">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <h1 className="card-title">Change your password.</h1>
                    <div className="form-error text-danger">{errorMessage}</div>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <fieldset id="me" disabled={isSaving}>
                            <div className="form-floating">
                                <input {...register('email_address')} autoFocus type="text" className="form-control"
                                       id="floatingEmail" placeholder="name@example.com"/>
                                <label htmlFor="floatingEmail">Email address</label>
                                <small className="text-danger">
                                    {errors?.email_address?.message?.toString()}
                                </small>
                            </div>
                            <div className="form-floating">
                                <input {...register('verification_code')} type="text" className="form-control"
                                       id="floatingVerificationCode" placeholder="123456"/>
                                <label htmlFor="floatingVerificationCode">Verification code</label>
                                <small className="text-danger">
                                    {errors?.verification_code?.message?.toString()}
                                </small>
                            </div>
                            <div className="form-floating">
                                <input {...register('password')} type="password" className="form-control"
                                       id="floatingPassword" placeholder="Password"/>
                                <label htmlFor="floatingPassword">New password</label>
                                <small className="text-danger">
                                    {errors?.password?.message?.toString()}
                                </small>
                            </div>
                            <div className="form-floating">
                                <input {...register('passwordConfirm')} type="password" className="form-control"
                                       id="floatingPasswordConfirm" placeholder="Password"/>
                                <label htmlFor="floatingPasswordConfirm">Re-enter new password</label>
                                <small className="text-danger">
                                    {errors?.passwordConfirm?.message?.toString()}
                                </small>
                            </div>
                            <div>
                                <button disabled={!isDirty || !isValid} type="submit"
                                        className="btn btn-lg btn-primary w-100">
                                    {isSaving &&
                                        <span className="spinner">
                                            <span className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span>
                                        </span>
                                    }
                                    Change password
                                </button>
                            </div>
                        </fieldset>
                    </form>
                    <div className="row">
                        <div className="col-6">
                            <Link to={ClientRoutes.userChangePasswordRequest} className="float-start">Resend
                                email</Link>
                        </div>
                        <div className="col-6">
                            <Link to={ClientRoutes.userSignIn} className="float-end">Sign in</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}