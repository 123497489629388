import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from './App';
import './custom.scss';
import './index.css';
import {AuthProvider} from "./AuthProvider";
import {CompanyProvider} from "./CompanyProvider";
import {AnalysisRoot} from "./features/analysis/components/AnalysisRoot";
import {ClientRoutes} from './features/clientRoutes';
import NotFound from "./features/common/NotFound";
import NotFoundRoot from "./features/common/NotFoundRoot";
import {CompanyRoot} from "./features/companies/components/CompanyRoot";
import PrivacyPolicy from "./features/public/PrivacyPolicy";
import TermsOfUse from "./features/public/TermsOfUse";
import {ReportRoot} from "./features/reports/components/ReportRoot";
import UserSignIn from "./features/user/UserSignIn";
import UserSignOut from './features/user/UserSignOut';
import UserSignUp from './features/user/UserSignUp';
import reportWebVitals from './reportWebVitals';
import RouteGuard from './RouteGuard';
import UserEmailVerification from './routes/user/userEmailVerification';
import UserEmailVerificationRequest from './routes/user/userEmailVerificationRequest';
import UserEmailVerificationStatus from './routes/user/userEmailVerificationStatus';
import UserPasswordChangeConfirmation from './routes/user/userPasswordChangeConfirmation';
import UserPasswordChangeRequest from './routes/user/userPasswordChangeRequest';
import UserPasswordChangeStatus from './routes/user/userPasswordChangeStatus';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    // <React.StrictMode>
    <AuthProvider>
        <CompanyProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App/>}>
                        {/*<Route*/}
                        {/*    path={ClientRoutes.home}*/}
                        {/*    element={<RouteGuard><Dashboard/></RouteGuard>}*/}
                        {/*/>*/}

                        <Route
                            path={ClientRoutes.company}
                            element={<RouteGuard><CompanyRoot/></RouteGuard>}
                        />

                        <Route
                            path={ClientRoutes.companyNew}
                            element={<RouteGuard><CompanyRoot/></RouteGuard>}
                        />

                        <Route
                            path={ClientRoutes.reports}
                            element={<RouteGuard><ReportRoot/></RouteGuard>}
                        />

                        <Route
                            path={ClientRoutes.reportsAdd}
                            element={<RouteGuard><ReportRoot/></RouteGuard>}
                        />

                        <Route
                            path={ClientRoutes.home}
                            element={<RouteGuard><AnalysisRoot/></RouteGuard>}
                        />

                        <Route path={ClientRoutes.userSignIn} element={<UserSignIn/>}/>
                        <Route path={ClientRoutes.userSignOut} element={<UserSignOut/>}/>
                        <Route path={ClientRoutes.userSignUp} element={<UserSignUp/>}/>

                        <Route path={ClientRoutes.userEmailVerification} element={<UserEmailVerification/>}/>
                        <Route path={ClientRoutes.userEmailVerificationRequest}
                               element={<UserEmailVerificationRequest/>}/>
                        <Route path={ClientRoutes.userEmailVerificationStatus}
                               element={<UserEmailVerificationStatus/>}/>

                        <Route path={ClientRoutes.userChangePasswordConfirmation}
                               element={<UserPasswordChangeConfirmation/>}/>
                        <Route path={ClientRoutes.userChangePasswordRequest}
                               element={<UserPasswordChangeRequest/>}/>
                        <Route path={ClientRoutes.userChangePasswordStatus}
                               element={<UserPasswordChangeStatus/>}/>

                        <Route path={ClientRoutes.publicPrivacyPolicy}
                               element={<PrivacyPolicy/>}/>
                        <Route path={ClientRoutes.publicTermsOfUse}
                               element={<TermsOfUse/>}/>

                        <Route path={ClientRoutes.notFound}
                               element={<NotFound/>}/>

                        <Route
                            path="*"
                            element={<NotFoundRoot/>}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </CompanyProvider>
    </AuthProvider>
    // {/*</React.StrictMode>*/}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
