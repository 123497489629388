import axios from 'axios';

import {AppConstants} from '../appConstants';

export async function requestPost(url: string, formData: any, config: any = undefined) {
    let data: any = null;
    let requireAuth: boolean = false;
    let errorSummary = '';
    const errorList: any[] = [];
    let isCanceled: boolean = false;

    let firstErrorField = '';

    axios.defaults.timeout = 60000;

    // These are expected responses. This is the only way to get the error response from the server.
    const instance = axios.create({
        validateStatus: function (status) {
            return status >= 200;
        }
    });

    await instance.post(url, formData, config)
        .then((response) => {
            if (AppConstants.logResponse) {
                console.log('SUCCESS: ' + JSON.stringify(response, undefined, 4));
            }

            data = response.data;

            if (data.code === 'ok') {
                return;
            }

            if (data.code === 'user_unverified') {
                errorSummary = data.message;
            }

            // TODO: Fix other errors. Check API for all.

            // Validation errors.
            errorSummary = data.message;

            const errors = data.errors;

            if (errors?.length) {
                errors.forEach(function (value: any, index: number) {
                    if (index === 0) {
                        firstErrorField = value.field;
                    }

                    errorList.push(
                        {'field': value.field, 'error_message': value.error_message}
                    );
                });
            }
        })
        .catch(error => {
            if (error.message === 'canceled') {
                isCanceled = true;
                return;
            }

            if (AppConstants.logResponse) {
                console.log('ERROR: ' + JSON.stringify(error, undefined, 4));
            }

            if (!error.response) {
                errorSummary = error.message;
                return;
            }

            /* Occurs with network errors and CORS errors. */
            // if (!error.response) {
            //     errorSummary = error.toString();
            //     return;
            // }

            // TODO: Bug. This also occurs on unverified.
            if (error.response.status === 403) {
                requireAuth = true;
            }

            if (error.response) {
                const errors = error.response.data.errors;

                if (errors?.length) {
                    errors.forEach(function (value: any, index: number) {
                        if (index === 0) {
                            firstErrorField = value.field;
                        }

                        errorList.push(
                            {'field': value.field, 'error_message': value.error_message}
                        );
                    });
                }

                if (error.response.data.message) {
                    // Validation errors.
                    errorSummary = error.response.data.message;
                } else {
                    // Auth errors.
                    errorSummary = error.response.data.error_description;
                }
            } else {
                errorSummary = error.message;
            }
        });

    return {data, requireAuth, errorSummary, errorList, firstErrorField, isCanceled};
}