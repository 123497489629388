import {yupResolver} from '@hookform/resolvers/yup';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

import {ApiRoutes} from '../../apiRoutes';
import {PageTitles} from '../../features/pageTitles';
import {CustomizedState} from '../../utils/customizedState';
import {requestPost} from '../../utils/requestPost';
import {ClientRoutes} from '../../features/clientRoutes';
import Logo from '../../features/common/Logo';

export default function UserEmailVerificationStatus() {

    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as CustomizedState;

    useEffect(() => {
        document.title = PageTitles.userEmailVerificationRequest;
    }, []);

    const validationSchema = Yup.object().shape({
        email_address: Yup.string()
            .required('Email is required.')
            .max(255, 'Email must not exceed 255 characters.')
            .email('Email is not valid.')
    });

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setFocus,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        shouldFocusError: true
    });

    /* Set defaults, if they exist. */
    useEffect(() => {
        state &&
        reset({
            email_address: state?.emailAddress
        }, {keepDefaultValues: true});
    }, [state, reset]);

    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [firstErrorField, setFirstErrorField] = useState('');

    useEffect(() => {
        if (firstErrorField) {
            setFocus(firstErrorField);
        }
    }, [setFocus, firstErrorField]);

    const onSubmitHandler = async (data: any) => {
        setIsSaving(true);
        setFirstErrorField('');

        requestPost(ApiRoutes.userRequestEmailVerification, data)
            .then((response) => {
                setIsSaving(false);

                if (response.data.code !== 'ok') {
                    setErrorMessage(response.errorSummary);
                    setFirstErrorField(response.firstErrorField);

                    response.errorList.forEach(function (value: any) {
                        setError(value.field, {type: 'custom', message: value.error_message});
                    });
                } else {
                    setErrorMessage('');
                    navigate(ClientRoutes.userEmailVerificationStatus, {state: {'emailAddress': data.email_address}});
                }

                // if (response.errorSummary) {
                //     setErrorMessage(response.errorSummary)
                //     setFirstErrorField(response.firstErrorField);
                //
                //     response.errorList.forEach(function (value: any) {
                //         setError(value.field, {type: 'custom', message: value.error_message});
                //     });
                // } else {
                //     setErrorMessage('');
                //     navigate(ClientRoutes.userEmailVerificationStatus, {state: {'emailAddress': data.email_address}});
                // }
            });
    };

    return (
        <div className="container account mt-4 mt-md-5">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <Logo/>
                    <div className="card">
                        <div className="card-body">
                            <h1 className="card-title">Request email verification code.</h1>
                            <div className="form-error text-danger">{errorMessage}</div>

                            <div className="row">
                                <div className="col">
                                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                                        <fieldset id="me" disabled={isSaving}>
                                            <div className="form-floating">
                                                <input {...register('email_address')} autoFocus type="text"
                                                       className="form-control" id="floatingEmail"
                                                       placeholder="name@example.com"/>
                                                <label htmlFor="floatingEmail">Email address</label>
                                                <small className="text-danger">
                                                    {errors?.email_address?.message?.toString()}
                                                </small>
                                            </div>
                                            <div>
                                                <button disabled={!isDirty || !isValid} type="submit"
                                                        className="btn btn-lg btn-primary w-100">
                                                    {isSaving &&
                                                        <span className="spinner">
                                                            <span className="spinner-border spinner-border-sm"
                                                                  role="status" aria-hidden="true"></span>
                                                        </span>
                                                    }
                                                    Send code
                                                </button>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <Link to={ClientRoutes.userSignIn} className="float-start">Sign in</Link>
                                </div>
                                <div className="col-8">
                                    <Link to={ClientRoutes.userChangePasswordRequest} className="float-end">Forgot
                                        password</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}