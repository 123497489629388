import * as React from 'react';
import {FeatureStatus} from "../featureStatus";

export default function SubmitButton({isFormReady, featureStatus, text}: any) {

    const isBusy = featureStatus === FeatureStatus.IsSubmitting;

    // TODO: Fix button width CSS on SubmitButton.
    let className = 'btn btn-lg btn-primary ';

    if (text === 'Sign in' || text === 'Sign up') {
        className += 'w-100';
    }

    return (
        <button disabled={!isFormReady}
                type="submit"
                className={className}>
            {isBusy &&
                <span className="spinner">
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                </span>
            }
            {text}
        </button>
    );
}