import axios from 'axios';

import {AppConstants} from '../appConstants';

export async function postData(url: string, formData: any, config: any = undefined) {

    let data: any = null;
    let errorSummary = '';
    const errorList: any[] = [];
    let firstErrorField = '';

    axios.defaults.timeout = 60000;

    await axios.post(url, formData, config)
        .then((response) => {
            if (AppConstants.logResponse) {
                console.log('SUCCESS: ' + JSON.stringify(response, undefined, 4));
            }

            data = response.data;
        })
        .catch(error => {
            if (AppConstants.logResponse) {
                console.error('ERROR in post data: ' + JSON.stringify(error, undefined, 4));
            }

            if (error.response) {
                const errors = error.response.data.errors;

                if (errors?.length) {
                    errors.forEach(function (value: any, index: number) {
                        if (index === 0) {
                            firstErrorField = value.field;
                        }

                        errorList.push(
                            {'field': value.field, 'error_message': value.error_message}
                        );
                    });
                }

                if (error.response.data.message) {
                    // Validation errors.
                    errorSummary = error.response.data.message;
                } else {
                    // Auth errors.
                    errorSummary = error.response.data.error_description;
                }
            } else {
                errorSummary = error.message;
            }
        });

    return {data, errorSummary, errorList, firstErrorField};
}