import {useEffect, useState} from "react";
import {ApiRoutes} from "../../apiRoutes";
import {useAuth} from "../../AuthProvider";
import {fetchFile} from "../../utils/fetchFile";
import {getHeaders} from "../../utils/requestHeaders";
import {FeatureStatus} from "../featureStatus";

export default function useReportDownload(
    companyId: string,
    companyName: string,
    reportDate: string) {
    const {getToken} = useAuth();

    const [status, setStatus] = useState(FeatureStatus.IsReady);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const doDownload = async () => {
            if (status !== FeatureStatus.IsSubmitting) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(FeatureStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(FeatureStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            const data = {
                'company_id': companyId,
                'period_end_date': reportDate
            };

            // Remove all non-alphanumeric characters.
            const printableCompanyName = companyName.replace(/[^\p{L}\d]/gu, '');
            const fileName = `CFOdigital_${printableCompanyName}_${reportDate}.csv`;

            const uploadFile = await fetchFile(ApiRoutes.companyMetricsDownload, data, headers, signal, fileName);

            if (doExitEarly(uploadFile)) {
                return;
            }

            setStatus(FeatureStatus.IsReady);
        };

        doDownload().then();

        return () => {
            controller.abort();
        };

    }, [status, getToken, companyId, companyName, reportDate]);

    const downloadReport = () => {
        setStatus(FeatureStatus.IsSubmitting);
        setMessage('');
        setValidationErrors([]);
    };

    return {
        status, message, validationErrors, downloadReport
    };
}