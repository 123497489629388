import * as React from 'react';
import {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {PageTitles} from '../../features/pageTitles';
import {CustomizedState} from '../../utils/customizedState';
import {ClientRoutes} from '../../features/clientRoutes';

export default function UserEmailVerificationStatus() {

    const location = useLocation();
    const state = location.state as CustomizedState;

    useEffect(() => {
        document.title = PageTitles.userEmailVerificationStatus;
    }, []);

    return (
        <div className="container account mt-4 mt-md-5">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <h1 className="card-title">Verify your email.</h1>
                    <p>An email has been sent to <strong>{state.emailAddress || "confirm your address"}</strong>. Follow the directions in the email to verify your account.
                    </p>
                    <p>
                        <Link to={ClientRoutes.userEmailVerificationRequest} className="float-start">Didn&apos;t receive it?</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}